<template>
  <div class="card border-secondary">
    <div class="card-header p-2 bg-secondary text-light">ปิดรับ {{ bet.text }}</div>
    <div class="card-body p-2">

      <div class="list-numbers mt-1">
        <div v-for="item in items" class="list-number mb-1 p-1 alert-secondary">
          <div>
            <b-form-checkbox
              v-model="checkedNumbers"
              :value="item"
            />
          </div>
          <div>
            <span class="badge badge-secondary">{{ item }}</span>
          </div>
          <div class="text-right">
            <button class="btn btn-sm btn-outline-danger py-0" @click="deleteNumbers([item])"><i class="fas fa-trash-alt"></i></button>
          </div>
        </div>
      </div>

      <b-form-textarea
        v-model="inputNumbers"
        placeholder="เพิ่มเลขปิดรับ..."
        rows="2"
        max-rows="6"
        size="sm"
      ></b-form-textarea>
      <b-input-group class="mt-2 justify-content-end">
        <b-input-group-prepend is-text>
          <b-form-checkbox
            v-model="checkAll"
            :value="true"
            :unchecked-value="false"
          > เลือกทั้งหมด
          </b-form-checkbox>
        </b-input-group-prepend>
        <b-input-group-append>
          <b-button variant="danger" @click="deleteBulk"><i class="fas fa-trash-alt"></i></b-button>
          <b-button variant="success" @click="addNumbers"><i class="fas fa-plus"></i></b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import LottoService from '@/services/lottoService'
import _ from 'lodash'

export default {
  name: 'CloseNumberList',
  props: ['bet', 'data'],
  data() {
    return {
      inputNumbers: '',
      checkedNumbers: [],
      checkAll: false
    }
  },
  computed: {
    items() {
      return _.sortBy(this.data?.closeNumbers[this.bet.code], (n)=>{
        return parseInt(n)
      })
    },
    roundId() {
      return this.$store.state.globalMarket.round.roundId
    }
  },
  watch: {
    checkAll() {
      if(this.checkAll) {
        this.checkedNumbers = this.items
      }else{
        this.checkedNumbers = []
      }
    },
    roundId() {
      this.inputNumbers = ''
      this.checkedNumbers = []
      this.checkAll = false
    }
  },
  methods: {
    addNumbers() {
      const numbers = this.inputNumbers
        .replace(/[^0-9]/g, ' ')
        .split(' ')
        .filter((n)=>{
          return {
            threeNumberTop: 3,
            threeNumberTode: 3,
            twoNumberTop: 2,
            twoNumberBottom: 2,
            runTop: 1,
            runBottom: 1
          }[this.bet.code] === n.length
        })
        .reduce((ns, n) => {
          if (!ns.includes(n)) {
            ns.push(n)
          }
          return ns
        }, [])

      if(numbers.length === 0) {
        return Swal.fire({
          text: 'กรุณาใส่ตัวเลขให้ถูกต้อง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      LottoService.saveRoundSettingNumbers(this.data._id, {
        type: 'closeNumbers',
        list: [
          {
            code: this.bet.code,
            numbers: numbers
          }
        ]
      })
      .then((response)=>{
        console.log(response)
        if(response.success) {
          this.inputNumbers = ''
          this.$emit('reload')

          this.$notify({
            type: 'success',
            title: 'สำเร็จ!',
            text: 'เพิ่มข้อมูลเสร็จเรียบร้อย',
          })
        }else{
          throw new Error(response?.message || JSON.stringify(response?.data))
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: (e?.message || e?.data) || 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
    },
    deleteBulk() {
      this.deleteNumbers(this.checkedNumbers)
    },
    deleteNumbers(numbers) {
      if(numbers.length === 0) {
        return Swal.fire({
          text: 'กรุณาเลือกตัวเลขที่ต้องการลบ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

      Swal.fire({
        title: 'ยืนยันลบ!',
        icon: 'info',
        confirmButtonText: 'ยืนยัน',
        confirmButtonColor: '#3085d6',
        showCancelButton: true,
        cancelButtonText: 'ยกเลิก'
      })
      .then((res)=>{
        if(res.isConfirmed) {
          LottoService.deleteRoundSettingNumbers(this.data._id, {
            type: 'closeNumbers',
            list: [
              {
                code: this.bet.code,
                numbers: numbers
              }
            ]
          })
          .then((response)=>{
            if(response.success) {
              this.checkAll = false
              this.checkedNumbers = []
              this.$emit('reload')

              this.$notify({
                type: 'success',
                title: 'สำเร็จ!',
                text: 'ลบเสร็จเรียบร้อย',
              })
            }else{
              throw new Error()
            }
          })
          .catch((e)=>{
            Swal.fire({
              title: 'ผิดพลาด!',
              text: 'ลบไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
              icon: 'error',
              confirmButtonText: 'OK'
            })
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.list-numbers {
  .list-number {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 4px;
    align-items: center;

    div {
      width: 33%;

      .badge {
        font-size: 100%;
        font-weight: normal;
      }
    }
  }
}
</style>
